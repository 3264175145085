//
// Only Overwrites and Definitions
// no duplication
//------------------------------------------------------------------------

// Body
//
// Settings for the `<body>` element.

$body-color: #111;

$container-max-widths: (
  xs: 750px,
  sm: 750px,
  md: 750px,
  lg: 970px,
  xl: 1170px
);


@function color($key) {
  @return map-get($theme-colors, $key);
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

// breakpoints

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($upper, $breakpoints);
  $min: breakpoint-min($lower, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  $min: breakpoint-min($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
      0: 0,
      "quarter": ($spacer * .25), // 4px
      "half": ($spacer * .5), // 8px
      "single": $spacer, // 16px
      "bigger": ($spacer * 1.5), // 24px
      "double": ($spacer * 2), // 32px
      "two-and-half": ($spacer * 2.5), // 40px
      "triple": ($spacer * 3), // 48px
      "three-and-half": ($spacer * 3.5), // 56px
      "quad": ($spacer * 4), // 64px
      "four-and-half": ($spacer * 4.5), // 72px
      "fivetimes": ($spacer * 5), // 80px
      "sixtimes": ($spacer * 6), // 96px
      "six-and-half": ($spacer * 6.5), // 104px
      "seventimes": ($spacer * 7) // 112px
    ),
    $spacers
);


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 376px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

//define navigation breakpoint globally
$navBreakpoint: "xl";

$container-max-widths: (
  xs: 750px,
  sm: 750px,
  md: 750px,
  lg: 970px,
  xl: 1170px
);

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// Modular Scale

$min-width: map-get($grid-breakpoints, "sm");
$max-width: map-get($grid-breakpoints, "xl");
$min-font: 16px;
$max-font: 20px;
$modularscale: (
  base: 16px,
  ratio: $major-second,
  450px: (
    ratio: $major-second,
  ),
  900px: (
    base: 16px,
    ratio: $major-second,
  ),
  1350px: (
    base: 18px,
    ratio: $minor-third,
  ),
);


// Hyphenatation
@mixin hyphenate() {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

// Typography
//
// naming and variables are based on exported invision text styles
$heading-font-weight: bold;
$heading-font-family: "bluu";

h1 {
  font-style: italic;
  line-height: 1.15;
}

h2 {
}

h3 {
}

h4 {
}

@mixin font--alpha() {
  @include ms-respond(font-size, 8);
  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--beta() {
  @include ms-respond(font-size, 5);

  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--gamma() {
  @include ms-respond(font-size, 3);

  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--delta() {
  @include ms-respond(font-size, 2);
  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--epsilon() {
  @include ms-respond(font-size, 1);
  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--eta() {
  @include ms-respond(font-size, 1);
  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--theta() {
  @include ms-respond(font-size, 0);
  font-weight: $heading-font-weight;
  line-height: 1.15;
}

@mixin font--iota() {
  @include ms-respond(font-size, 1);
  font-weight: 400;
  line-height: 1.8;
}

@mixin font--kappa() {
  @include ms-respond(font-size, 0);
  font-weight: 400;
  line-height: 1.15;
}

@mixin font--omega() {
  @include ms-respond(font-size, -2);
  font-weight: 400;
  line-height: 1.45;
}


// Headlines

@mixin headline--alpha() {
  @include font--alpha();
  margin-bottom: map-get($spacers, "bigger");

  // spacing
  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    margin-bottom: map-get($spacers, "double");

  }
}

@mixin headline--beta() {
  @include font--beta();
  margin-bottom: map-get($spacers, "single");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    margin-bottom: map-get($spacers, "bigger");
  }
}

@mixin headline--gamma() {
  @include font--gamma();
  margin-bottom: map-get($spacers, "single");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

@mixin headline--delta() {
  @include font--delta();
  margin-bottom: map-get($spacers, "half");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    margin-bottom: map-get($spacers, "single");

  }
}

@mixin headline--epsilon() {
  @include font--epsilon();
  margin-bottom: map-get($spacers, "half");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

@mixin text--eta() {
  @include font--eta();

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    margin-bottom: map-get($spacers, "double");

  }
}

@mixin text--quote() {
  @include font--theta();
  margin-bottom: map-get($spacers, "double");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

@mixin text--paragraph() {
  @include font--iota();
  &:not(:last-child) {
    margin-bottom: map-get($spacers, "double");
  }

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

@mixin text--link() {
  @include font--iota();
  color: map-get($theme-colors, "primary");
  outline: none;
  text-decoration: none;
  transition: all 150ms ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: lighten(map-get($theme-colors, "primary"), 10%);
    text-decoration: none;
    transition: all 150ms ease-in-out;
  }

  // removes underline for link with classes
  &:not([class]) {
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

@mixin text--kappa() {
  @include font--kappa();
  margin-bottom: map-get($spacers, "single");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

@mixin text--omega() {
  @include font--omega();
  margin-bottom: map-get($spacers, "single");

  // spacing
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}

// Lists
@mixin list-style-reset() {
  list-style: none;
  padding-left: 0;
}

// abstract focus behaviour
@mixin focus-behaviour() {
  &:focus, &.is-focused {
    box-shadow: 0 0 2px 1px #2cd0fe;
    outline: 0;
  }
}

// Text Contrast
//------------------------
//mixin that calculates if text needs to be light or dark
//depending on the background color passed.
//
//From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
//
//Color brightness is determined by the following formula:
//((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
//------------------------

@mixin text-contrast($n) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
  $light-color: round((red(#fff) * 299) + (green(#fff) * 587) + (blue(#fff) * 114) / 1000);

  @if abs($color-brightness) < ($light-color / 1.51) {
    color: $white-negative;
    content: #{$color-brightness};
    content: #{$light-color};
    content: #{$light-color / 1.51};
  } @else {
    color: $medi-grey-100;
    content: #{$color-brightness};
    content: #{$light-color};
    content: #{$light-color / 1.51};
  }
}

