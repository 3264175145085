.a-marker {
  background: map-get($theme-colors, "primary");
  border-radius: 9999px;
  cursor: pointer;
  display: block;
  height: 10px;
  transition: 300ms ease width, 300ms ease height;
  width: 10px;

  &:hover {
    height: 15px;
    transform: scale(1.1);
    transition: 300ms ease width, 300ms ease height;
    width: 15px;
  }

}
