body {
  color: map-get($theme-colors, "body");
  font-family: "PT Serif", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

//define text styling over class
.alpha {
  @include font--alpha();
}

.beta {
  @include font--beta();
}

.gamma {
  @include font--gamma();
}

.delta {
  @include font--delta();
}

.epsilon {
  @include font--epsilon();
}


.eta {
  @include font--eta();
}

.theta {
  @include font--theta();
}

.iota {
  @include font--iota();
}

.kappa {
  @include font--kappa();
}

.omega {
  @include font--omega();
}

//define text styling over tag
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  margin-top: 0;
  width: 100%;
}

h1 {
  @include headline--alpha();
}

h2 {
  @include headline--beta();
}

h3 {
  @include headline--gamma();
}

h4 {
  @include headline--delta();
}

h5 {
  @include headline--epsilon();
}


quote, cite {
  @include text--quote();
}

p {
  @include text--paragraph();
}

ol, ul, li, td, tr, table {
  @include font--iota();
}

a {
  @include text--link();
}
