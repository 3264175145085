.o-text {
  display: flex;
  justify-content: center;

  margin: 1rem 0;
  padding: 1rem 0;

  &__row {
    flex-direction: row;
    position: relative;
    display: flex;
  }

  &__column {
    display: flex;
    flex-direction: column;


    &:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}
