.m-about-link {
  bottom: map-get($spacers, "double");
  position: absolute;
  right: map-get($spacers, "double");
  z-index: 3;
  @include media-breakpoint-up(md) {
    right: map-get($spacers, "double");
    top: map-get($spacers, "double");
  }

  a {
    background: map-get($theme-colors, "white");
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: normal;
    padding: map-get($spacers, "half") map-get($spacers, "single");
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
      padding: map-get($spacers, "quarter") map-get($spacers, "half");

    }
  }
}
