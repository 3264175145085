.o-posts {
  $oPosts: &;
  bottom: map-get($spacers, "double");
  left: map-get($spacers, "double");
  max-width: 320px;
  overflow: hidden;
  padding-right: .25rem;
  position: fixed;
  width: 100%;
  z-index: 2;


  @include media-breakpoint-up(lg) {
    bottom: auto;
    left: map-get($spacers, "double");
    max-width: 380px;
    top: map-get($spacers, "double");
  }

  &__toggle {
    background: map-get($theme-colors, "white");
    bottom: 0;
    cursor: pointer;
    display: inline-block;
    left: 0;
    padding: map-get($spacers, "half") map-get($spacers, "single");
    position: relative;
    text-transform: uppercase;
    user-select: none;
  }

  &__input {
    display: none;
    visibility: hidden;

    &:checked ~ #{$oPosts}__list {
      max-height: 90vh;

      overflow: auto;
      transition: max-height 300ms ease-in-out;
      //padding: map-get($spacers, "half");

      @include media-breakpoint-up(lg) {
        max-height: 80vh;
      }
    }
  }

  &__list {
    background: map-get($theme-colors, "white");
    bottom: 40px;
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    position: relative;
    top: 8px;
    transition: max-height 150ms ease-in-out;

    @include media-breakpoint-up(lg) {
      //max-height: 90vh;
      background: transparent;
    }
  }
}
