.o-cards {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  &:after {
    content: "";
    flex: 47%;
    margin-left: map-get($spacers, "single")
  }
}
