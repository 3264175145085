.a-popup {
  $aPopup: &;
  color: map-get($theme-colors, "body");
  z-index: 3;

  .m-map .mapboxgl-popup .mapboxgl-popup-content & {
    box-shadow: none;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);


    &__wrapper {
      background: map-get($theme-colors, "grey");
      border: 1px solid lighten(map-get($theme-colors, "body"), 30%);
      display: flex;
      flex-direction: column;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }

    &__header {
      align-items: center;
      border-bottom: 1px solid lighten(map-get($theme-colors, "body"), 30%);
      display: flex;
      flex-direction: row;
    }

    &__media {
      border-radius: 9999px;
      display: block;
      flex: 1 0 40px;
      height: 40px;
      margin: map-get($spacers, "half") map-get($spacers, "half") map-get($spacers, "half") 0;
      overflow: hidden;

      img {
        object-fit: contain;
      }
    }

    &__headline {
      @include font--theta();
      margin-bottom: 0;
      padding: map-get($spacers, "half") 0 map-get($spacers, "half") map-get($spacers, "half");
      text-transform: uppercase;
    }

    &__excerpt {
      color: map-get($theme-colors, "body");
      font-family: "Noto Sans", sans-serif;
      margin: 0;
      @include font--omega();
      min-height: 60px;
      padding: map-get($spacers, "half");
    }

    &__notification {
      background: map-get($theme-colors, "secondary");
      margin: 0;
      padding: map-get($spacers, "half");
    }

    &__footer {
      border-top: 1px solid lighten(map-get($theme-colors, "body"), 30%);
      display: flex;
      justify-content: flex-end;
    }


    &__cta {
      @include font--omega();
      border-left: 1px solid lighten(map-get($theme-colors, "body"), 30%);
      flex: 0 1 100px;
      font-weight: bold;
      padding: map-get($spacers, "half");
      text-align: right;
      text-transform: uppercase;

      &:hover {
        background: map-get($theme-colors, "light-grey")
      }
    }

    &:hover {
      #{$aPopup}__wrapper {
        background: #fff;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transform: translateY(-1px);
      }

      .mapboxgl-popup-tip {
        transform: translateY(-2px);
        transition: all 0.2s cubic-bezier(.25, .8, .25, 1);


      }
    }
  }

  &.mapboxgl-popup-anchor-top {
    .mapboxgl-popup-tip {
      border-bottom-color: map-get($theme-colors, "body");
      transition: all 0.2s cubic-bezier(.25, .8, .25, 1);
    }
  }


}
