.a-post-item {
  margin-bottom: map-get($spacers, "half");

  &__link {
    align-items: center;
    display: flex;
    flex: 1 100%;

    flex-direction: row;
    justify-content: space-between;

    padding: map-get($spacers, "quarter") map-get($spacers, "half");
    transition: 250ms ease all;

    @include media-breakpoint-up(lg) {
      background: map-get($theme-colors, "white");
    }

    &:hover {
      background: map-get($theme-colors, "light-grey");
    }


  }

  &__title {
    flex: 1 auto;
    padding: 0 0 0 map-get($spacers, "half");
    text-align: left;
    white-space: nowrap;

    h2 {
      margin-bottom: 0;
    }
  }

  &__media {
    background: map-get($theme-colors, "light-grey");
    border-radius: 999px;
    flex: 0 0 30px;
    height: 30px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  &__icon {
    flex: 0 1 24px;
    padding-top: 4px;

  }
}
