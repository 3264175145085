.o-gallery {

  margin: 3rem 0;
  padding: 1rem 0;
  @include media-breakpoint-up(md) {
    margin-left: -10vw;
    margin-right: -10vw;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    padding: 0.4vw;
    margin: auto;
  }

  &__item {
    overflow: hidden;
    flex: auto;
    width: 200px;
    margin: 0.5vw;
    position: relative;
  }
}
