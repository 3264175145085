.o-text-image {
  display: flex;
  justify-content: center;

  margin: 3rem 0;
  padding: 1rem 0;
  @include media-breakpoint-up(md) {
    margin-left: -10vw;
    margin-right: -10vw;
  }

  &__row {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-basis: 48%;
      width: 48%;
    }


    &:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}
