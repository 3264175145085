.g-wrap {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  @include make-container-max-widths();

  .row.center-xs {
    text-align: left;
  }
}
