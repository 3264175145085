//- Tables
table, .a-table {
  $scrollBorderColor: rgba(color("body"), .05);

  //background: linear-gradient($scrollBorderColor 0%, $scrollBorderColor 100%) 0 0,
  //linear-gradient($scrollBorderColor 0 %, $scrollBorderColor 100 %) 100 % 0;
  background-size: 1px 100%, 1px 100%;
  border-collapse: collapse;
  border-spacing: 0;
  display: inline-block;
  max-width: 100%;
  overflow-x: auto;
  text-align: left;
  vertical-align: top;


  caption {
    background: color("secondary");
    font-size: .9em;
  }

  td, th {
    border: 1px solid #492622;
    border-left: 0;
    border-top: 0;
    font-size: .9em;
    padding: .35em .75em;
    vertical-align: top;

    &:first-child {
      background-image: linear-gradient(to right, rgba(color("white"), 1) 50%, rgba(color("white"), 0) 100%);
      background-size: 2px 100%;
      padding-left: 0;
    }

    &:last-child {
      background-image: linear-gradient(to left, rgba(color("white"), 1) 50%, rgba(color("white"), 0) 100%);
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: 2px 100%;
      border-right: 0;
      padding-right: 0;
    }

    &:only-child {
      background-image: linear-gradient(to right, rgba(color("white"), 1) 50%, rgba(color("white"), 0) 100%), linear-gradient(to left, rgba(color("white"), 1) 50%, rgba(color("white"), 0) 100%);
      background-position: 0 0, 100% 0;
      background-repeat: no-repeat, no-repeat;
      background-size: 2px 100%, 2px 100%;
    }
  }

  th {
    line-height: 1.7;
  }
}
