.o-header {
  $oHeader: &;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  //background: rgba(map-get($theme-colors, grey), 0.5);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  & ~ .m-map--static {
    max-height: 200px;
  }

  &__navigation {
  }

  &__logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: map-get($spacers, "single") auto map-get($spacers, "single");
    //font-size: .7rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }

  }

  &--interview {
    #{$oHeader}__logo {
      height: 2.5rem;
      margin: map-get($spacers, "single") auto map-get($spacers, "half");
    }
  }

  + .g-content {
    padding-top: 200px;

  }
}
