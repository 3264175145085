.a-icon {
  //default size only for basic display - will be set in molecules and organisms
  //width: 2rem;
  //height: 2rem;

  //use element from sprite - default fill
  use {
    fill: map-get($theme-colors, "primary");

    &.a-icon--alternative-icon {
      display: none;
    }
  }

  &.a-icon--logo {
    height: 5rem;
    width: 15rem;

  }
}
