.a-text {
  text-align: initial;

  // Form Label
  &--label {
    display: block;
    @include font--iota;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: map-get($spacers, "half");
  }

  &--centered {
    text-align: center;
  }

  &--link {
    color: map-get($theme-colors, "primary");
    font-weight: bold;

  }
}
