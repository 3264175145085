img {
  display: inline-block;
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0;
}

* {
  box-sizing: border-box;
}
