.m-navigation {
  display: flex;
  width: 100%;

  ul, li {
    @include list-style-reset();
    margin: 0;
  }

  &__main-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .a-menu-list {
    @include list-style-reset();
    display: flex;
    flex: 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    position: static;
  }

  .a-menu-item {
    display: inline-flex;
    @include media-breakpoint-up(lg) {
      &:not(:first-child) {
        &:before {
          background: map-get($theme-colors, "body");
          content: '';
          float: left;
          height: 1px;
          position: relative;
          top: 14px;
          width: 50px;
        }
      }

    }


    &__link {
      color: map-get($theme-colors, "body");
      display: inline-block;
      font-size: .8rem;
      font-weight: bold;
      padding: 0 10px;
      position: relative;
      text-transform: uppercase;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }

      &:after {
        background: map-get($theme-colors, "body");
        bottom: -6px;
        content: '';
        height: 4px;
        left: 0;
        margin: 0 auto;
        opacity: 0;
        position: absolute;
        right: 0;
        transform: rotate(45deg);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        visibility: hidden;
        width: 4px;

      }

      &:hover {
        transform: translateY(-2px);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

        &:after {
          opacity: 1;
          transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
          visibility: visible;
        }
      }
    }
  }
}
