
.m-newsletter {

  &--popup {
    flex: 1 100%;
    padding: 10px;

    #mce-EMAIL {
      border: 1px solid map-get($theme-colors, "body");
      border-radius: 0;
      border-radius: 0;
      box-sizing: border-box;
      display: inline-block;
      font-family: "Noto Sans", sans-serif;
      font-size: 1rem;
      height: 25px;
      margin-bottom: 1rem;
      padding: 1rem 0.5em;
      width: 100%;
      @include placeholder {
        font-family: "Noto Sans", sans-serif;
        text-transform: uppercase;
      }
    }


    #mc-embedded-subscribe {
      appearance: none;
      background: transparent;
      border: 2px solid map-get($theme-colors, "body");
      cursor: pointer;
      display: inline-block;
      flex: 1 1 30%;
      font-family: "Noto Sans", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      height: 2rem;
      padding: 0 2rem;
      text-transform: uppercase;
    }
  }

  &--big {
    background: map-get($theme-colors, "light-grey");
    box-shadow: 5px 5px;
    display: flex;
    flex-direction: column;
    margin: 0 -2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding: 2rem;

    }

    #mce-EMAIL {
      border: 1px solid map-get($theme-colors, "body");
      border-radius: 0;
      display: inline-block;
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
      height: 3rem;
      margin-bottom: 1rem;
      padding: 1rem;
      width: 100%;
      @include placeholder {
        font-family: "Noto Sans", sans-serif;
        text-transform: uppercase;
      }

      @include media-breakpoint-up(md) {
        font-size: 2rem;
        height: 6rem;
        margin-bottom: 0;
        padding: 2rem;
      }
    }

    #mc_embed_signup {
      clear: left;
      width: 100%;
    }

    #mce-responses {

      #mce-error-response {
        background: #990d0c;
        color: #fff;
        font-family: "Noto Sans", sans-serif;
        margin-bottom: 2rem;
        padding: 2rem;

      }

      #mce-success-response {
        background: #36570d;
        color: #fff;
        font-family: "Noto Sans", sans-serif;

        margin-bottom: 2rem;
        padding: 2rem;
      }
    }


    #mc_embed_signup_scroll {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    #mc-embedded-subscribe {
      appearance: none;
      background: transparent;
      border: 4px solid map-get($theme-colors, "body");
      display: inline-block;
      flex: 1 1 30%;
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      height: 3rem;
      padding: 0 2rem;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        font-size: 2rem;

        height: 6rem;
        padding: 0 4rem;
      }
    }

    #mc-embedded-subscribe-form input[type="checkbox"] {
      display: inline;
      margin-right: 10px;
      width: auto;
    }

    #mergeRow-gdpr {
      margin-top: 20px;
    }

    #mergeRow-gdpr fieldset label {
      font-weight: normal;
    }

    #mc-embedded-subscribe-form .mc_fieldset {
      border: 0;
      min-height: 0;
      padding-bottom: 0;
    }

  }
}
