$body-font-color: map-get($theme-colors, 'body');

$background-themes: (
  default: (
    background-color: map-get($theme-colors, 'white'),
    text-color: $body-font-color,
  ),
  light-grey: (
    background-color: map-get($theme-colors, 'light-grey'),
    text-color: $body-font-color,
  ),
  dark-grey: (
    background-color: map-get($theme-colors, 'grey'),
    text-color: $body-font-color,
  ),
);

@mixin background-theme($background-theme) {
  $currentTheme: map_get($background-themes, $background-theme);
  $ct-background-color: map_get($currentTheme, background-color);
  $ct-text-color: map_get($currentTheme, text-color);

  background: $ct-background-color;
  color: $ct-text-color;

  .m-news-teaser {
    background: $ct-text-color;

    .m-news-teaser__date,
    .m-news-teaser__title {
      color: $body-font-color;
    }
  }

  @if $background-theme == default {

  } @else if $background-theme == brand-color {
    background-repeat: no-repeat;
    background-size: cover;

    .a-download-link {
      background: $background-color;
      color: $body-font-color;
    }

  } @else if $background-theme == dark-highlight-color {
    .a-highlight-box--brown {
      &:before {
        background-color: $background-color;
      }

      .a-highlight-box__content {
        color: $dark-gray;
      }
    }
  } @else if $background-theme == dark-highlight-color or
        $background-theme == brand-color {

  }
}

@each $theme, $colors in $background-themes {
  .h-background-theme--#{$theme} {
    @include background-theme($theme);
  }
}
