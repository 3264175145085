//@import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css";

.m-map {
  background: #808080;
  height: 100vh;
  opacity: 0;
  position: relative;
  transition: 300ms ease all;
  visibility: hidden;
  width: 100%;
  //z-index: 0;

  &.is-loaded {
    opacity: 1;
    visibility: visible;
  }

  .mapboxgl-ctrl-attrib {
    &, * {
      font-size: 10px;
    }

  }

  .mapboxgl-ctrl-bottom-right {
    opacity: 0.1;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .mapboxgl-canvas-container {
    height: 100vh;
  }

  .mapboxgl-canvas {
    left: 0;
    right: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .marker {
    background: #f00;
    border-radius: 100%;
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

  .mapboxgl-popup {

    .mapboxgl-popup-close-button {
      display: none;
    }

    .mapboxgl-popup-content {
      background: transparent;
      border-radius: 0;

      padding: 0;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);


      h3 {
        margin-top: 0;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }
    }

    .mapboxgl-popup-content-wrapper {
      padding: 1%;
    }
  }

  &--static {
    position: relative;

    .marker {
      height: 20px;
      width: 20px;
    }

    &:after {
      background: rgb(255, 255, 255);
      background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, .3) 60%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.is-loaded {
      .mapboxgl-canvas-container {
        opacity: .8;
      }
    }
  }

}
