.t-maintenance {
  .g-wrap {
    overflow: visible;

    .row.center-xs {
      text-align: center;
    }
  }

  .a-text--headline {
    @include headline--gamma();
  }

}
