.m-card {
  border: 4px solid map-get($theme-colors, "body");
  display: flex;
  flex-basis: 47%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 map-get($spacers, "half") map-get($spacers, "single");
  transition: 250ms ease all;

  @include media-breakpoint-up(md) {
    flex-basis: 47%;
  }

  a {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__image {
    background: map-get($theme-colors, "grey");
    height: 180px;
    overflow: hidden;
  }

  .a-image {
    height: 100px;
    object-fit: cover;
    width: 100%;
    @include media-breakpoint-up(md) {
      height: 180px;
    }
  }

  .a-text {
    color: map-get($theme-colors, "body");
    padding-left: map-get($spacers, "bigger");
    padding-right: map-get($spacers, "bigger");
    pointer-events: none;

    &--headline {
      display: block;
      margin-top: map-get($spacers, "bigger");
    }

    &--link {
      color: map-get($theme-colors, "primary");
      display: block;
      margin-bottom: map-get($spacers, "single");
    }

    &--excerpt {
      //flex: 1;
    }
  }

  &__content {
    //flex: 1;
    flex: 1;
  }

  &:hover {
    background: map-get($theme-colors, "light-grey");
  }
}
